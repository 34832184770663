
import { toast } from "react-toastify";


const copyLink = (id) => {
    console.log(id, 'id')
    const baseUrl = 'https://tradeitfwd.com';

    const generateRandomString = (length) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };

    const randomString = generateRandomString(1)
    const referralLink = `${baseUrl}/r?${id}&${randomString}`;
    navigator.clipboard.writeText(referralLink);
    toast.success('Referral link copied to clipboard!', {
        position: 'top-center',
        containerId: "main",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    })
}


export {
    copyLink
}