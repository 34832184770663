// Action types for authentication
export const GET_ERRORS = "GET_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_SUCCESS = "SET_REGISTER_CUSTOMER";
export const SET_USER = "SET_USER";
export const SET_AVATAR = "SET_AVATAR";
export const SET_ERROR = "SET_ERROR";

// Action types for favorites
export const SET_CURRENT_FAVORITES = "SET_CURRENT_FAVORITES";
export const ADD_TO_FAVORITES = "ADD_TO_FAVORITES";
export const REMOVE_FROM_FAVORITES = "REMOVE_FROM_FAVORITES";
export const CLEAR_FAVORITES = "CLEAR_FAVORITES";

// Action types for notifications
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const REMOVE_NOTIFICATION = "DELETE_NOTIFICATION";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";

// Action types for search
export const SET_KEYWORD = "SET_KEYWORD";
export const SET_RADIUS = "SET_RADIUS";
export const SET_TYPE = "SET_TYPE";
export const SET_LOCATION = "SET_LOCATION";
export const UPDATE_BALANCE = "UPDATE_BALANCE";
