import { toast } from "react-toastify";
import api from "../api";
import {
  GET_ERRORS,
  SET_AVATAR,
  SET_USER,
  SET_ERROR,
  GET_SUCCESS,
  UPDATE_BALANCE
} from "./types";

// This function is used to register a user
export const registerUser =
  (userData, history, setClickStatus, setEmailSentStatus) => (dispatch) => {
    const queryString = window.location.search
    console.log(queryString, 'queryString')
    const referrerIdMatch = queryString.match(/\?([^&]*)/)
    const referrerId = referrerIdMatch ? referrerIdMatch[1] : null
    console.log(referrerId, 'referrerValue in frontend')
    const registrationData = { ...userData, referrerId }

    api
      .post("/auth/register", registrationData)
      .then((res) => {
        console.log(res, 'register')
        if (res && res.data.success) {
          // Dispatch success action
          dispatch({
            type: GET_SUCCESS,
            payload: "Success",
          });

          if (res.data.balance !== undefined) {
            dispatch({
              type: UPDATE_BALANCE, // Use the constant action type
              payload: res.data.balance,
            });
          }
          toast.success("Successfully Registered.", {
            position: "top-center",
            containerId: "main",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          toast.success(
            "Please check your email and follow the instructions to verify your email address.",
            {
              position: "top-center",
              containerId: "main",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );

          setEmailSentStatus(true);
        }
        setClickStatus(false);
      })
      .catch((err) => {
        // Handle errors
        let message = err.message.match(/422/i)
          ? "User already exists with the same email."
          : "Internal Server Error(s)";

        toast.error(message, {
          position: "top-center",
          containerId: "main",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        dispatch({
          type: GET_ERRORS,
          payload: "Database error!",
        });
        setClickStatus(false);
      });
  };


// This function is used to login a user 
export const loginUser = (userData, history, setClicked) => (dispatch) => {
  // Make an API call to the server
  api
    .post("/auth/login", userData)
    .then((res) => {
      console.log(res, 'res in login ')
      // If the response is successful, store the token and user data in localStorage
      if (res.data.success) {
        const token = res.data.data.token;
        localStorage.setItem("jwtToken", token);
        // localStorage.setItem("user", JSON.stringify(res.data.data.user));
        dispatch(setCurrentUser(res.data.data.user));
        // Show a success toast message
        toast.success("Logged in Successfully", {
          position: "top-center",
          containerId: "main",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setClicked(false);
    })
    .catch((err) => {
      // Get error message
      let message;

      // Dispatch an error action
      dispatch({
        type: SET_ERROR,
        payload: "",
      });
      if (err.message.match(/400/i)) {
        message = "You are not registered!";
      } else if (err.message.match(/401/i)) {
        message = "Your password is incorrect.";
      } else if (err.message.match(/403/i)) {
        message = "Your email is not verified yet.";
      } else {
        message = "Internal Server Error(s)";
      }
      // Show an error toast message
      toast.error(message, {
        position: "top-center",
        containerId: "main",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setClicked(false);
    });
};

// This function is used to set the current user
export const setCurrentUser = (decoded_data) => ({
  type: SET_USER,
  payload: {
    decoded_data,
  },
});

// This function is used to set the avatar of the user
export const setAvatar = (avatar) => (dispatch) => {
  // Get the user data from localStorage
  // let tmp = JSON.parse(localStorage.user);
  // Set the avatar of the user
  // tmp["avatar"] = avatar;
  // Store the updated user data in localStorage
  // localStorage.setItem("user", JSON.stringify(tmp));
  // Dispatch an action with the updated user data
  dispatch({
    type: SET_AVATAR,
    payload: {
      avatar: avatar,
    },
  });
};

// This function is used to logout the user
export const logoutUser = () => (dispatch) => {
  // Remove the token and user data from localStorage
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("user");
  // Dispatch an action to set the current user to empty object
  dispatch(setCurrentUser({}));
  // Redirect the user to the login page
  // window.location.href = "/";
};

export const logoutOnUnload = () => (dispatch) => {
  // window.addEventListener("beforeunload", () => {
  //   dispatch(logoutUser());
  // });
};